import API from "../../API.js";
import { REQUEST_METHODS } from "../../configs/";
export default {
  state: {
    requestError: null,
    organizations: [],
  },
  getters: {},
  mutations: {
    updateOrganizationName(state, value) {
      state.id = value.toString().trim();
    },
    updateRequestError(state, value) {
      state.requestError = value;
    },
    updateOrganizations(state, value) {
      state.organizations = value;
    },
    addOrganization(state, value) {      
      state.organizations.data.push(value.data);
    },
    updateOrganization(state, value) {
      console.log("updateOrganization --> ",value.data)
      const organizationIndex = state.organizations.data.findIndex(
        (organization) => organization.id === value.data.id
      );
      state.organizations.data.splice(organizationIndex, 1, value.data);
    },
    deleteOrganization(state, value) {
      const organizationIndex = state.organizations.data.findIndex(
        (organization) => organization.id === value
      );
      state.organizations.data.splice(organizationIndex, 1);
    },
  },
  actions: {
    async fetchOrganizations({ commit }) {
      await API(REQUEST_METHODS.GET, "/companies?limit=10000")
        .then(( response ) => {
          // console.log('ORGANIZATIONS FETCHED ---->',response);
          commit("updateOrganizations", response, { module: "organization" });
        })
        .catch((err) => {
          console.log(err);
          // add it to products error message
          commit("updateRequestError", err.message, { module: "organization" });
        });
    },
    async createOrganization({ commit }, item) {
      commit("updateRequestError", null, { module: "organization" });
      await API(REQUEST_METHODS.POST, "/companies", item)
        .then(( createdOrganization ) => {
          console.log("createdOrganization --> ",createdOrganization)
          commit("addOrganization", createdOrganization, { module: "organization" });
        })
        .catch((err) => {
          console.error(err);
          commit("updateRequestError", err.message, { module: "organization" });
        });
    },
    async updateOrganization({ commit }, item) {
      commit("updateRequestError", null, { module: "organization" });
      await API(REQUEST_METHODS.PUT, `/companies/${item.id}`, item)
        .then(( updatedOrganization ) => {
          console.log("updatedOrganization --> ",updatedOrganization)
          commit("updateOrganization", updatedOrganization, { module: "organization" });
        })
        .catch((err) => {
          console.error(err);
          commit("updateRequestError", err.message, { module: "organization" });
        });
    },
    async deleteOrganization({ commit }, id) {
      commit("updateRequestError", null, { module: "organization" });
      await API(REQUEST_METHODS.DELETE, `/companies/${id}`)
        .then((  ) => {
          commit("deleteOrganization", id, { module: "organization" });
        })
        .catch((err) => {
          console.error(err);
          commit("updateRequestError", err.message, { module: "organization" });
        });
    },
  },
  namespaced: true,
};
