import API from "../../API.js";
import { REQUEST_METHODS } from "../../configs/";
export default {
	state: {
		requestError: null,
		transactions: [],
		activeLoans: [],
		pendingLoans: [],
		settlements: [],
	},
	getters: {},
	mutations: {
		updateTransactionName(state, value) {
			state.id = value.toString().trim();
		},
		updateRequestError(state, value) {
			state.requestError = value;
		},
		updateTransactions(state, value) {
			state.transactions = value;
		},
		updateActiveLoans(state, value) {
			state.activeLoans = value;
		},
		updatePendingLoans(state, value) {
			state.pendingLoans = value;
		},
		updateSettlements(state, value) {
			state.settlements = value;
		},
		addTransaction(state, value) {
			state.transactions.data.push(value);
		},
		updateTransaction(state, value) {
			const transactionIndex = state.transactions.data.findIndex(
				(transaction) => transaction.id === value.id
			);
			state.transactions.data.splice(transactionIndex, 1, value);
		},
		updateManualDisbursement(state, value) {
			console.log("updatedTransaction --------##---> ", value.data);
			const transactionIndex = state.pendingLoans.data.findIndex(
				(pendingLoan) => pendingLoan.id === value.data.id
			);
			state.pendingLoans.data.splice(transactionIndex, 1);
			state.pendingLoans.total = state.pendingLoans.total - 1;
			const totalAmountString = state.pendingLoans.totalAmt;
			const amountString = value.data.amount;
			// Convert strings to doubles (numbers)
			const totalAmount = parseFloat(totalAmountString);
			const amount = parseFloat(amountString);
			// Perform the subtraction
			const result = totalAmount - amount;
      console.log("SUBTRACTION RESULT = ",result)
      state.pendingLoans.totalAmt = parseFloat(result.toFixed(2))
		},
		deleteTransaction(state, value) {
			const transactionIndex = state.transactions.data.findIndex(
				(transaction) => transaction.id === value.id
			);
			state.transactions.data.splice(transactionIndex, 1);
		},
	},
	actions: {
		async fetchTransactions({ commit }) {
			await API(REQUEST_METHODS.GET, "/transactions?limit=10000")
				.then((response) => {
					console.log("TRANSACTIONS FETCHED ---->", response);
					commit("updateTransactions", response, { module: "transaction" });
				})
				.catch((err) => {
					console.log(err);
					// add it to transactions error message
					commit("updateRequestError", err.message, { module: "transaction" });
				});
		},
		async fetchActiveLoans({ commit }) {
			await API(
				REQUEST_METHODS.GET,
				"/transactions?limit=10000&transactionTypeId=1&statusId=1"
			)
				.then((response) => {
					console.log("ACTIVE LOANS FETCHED ---->", response);
					commit("updateActiveLoans", response, { module: "transaction" });
				})
				.catch((err) => {
					console.log(err);
					// add it to transactions error message
					commit("updateRequestError", err.message, { module: "transaction" });
				});
		},
		async fetchPendingLoans({ commit }) {
			await API(
				REQUEST_METHODS.GET,
				"/transactions?limit=10000&transactionTypeId=1&statusId=3"
			)
				.then((response) => {
					console.log("PENDING LOANS FETCHED ---->", response);
					commit("updatePendingLoans", response, { module: "transaction" });
				})
				.catch((err) => {
					console.log(err);
					// add it to transactions error message
					commit("updateRequestError", err.message, { module: "transaction" });
				});
		},
		async fetchSettlements({ commit }) {
			await API(
				REQUEST_METHODS.GET,
				"/transactions?limit=10000&transactionTypeId=5&statusId=1"
			)
				.then((response) => {
					console.log("SETTLEMENTS FETCHED ---->", response);
					commit("updateSettlements", response, { module: "transaction" });
				})
				.catch((err) => {
					console.log(err);
					// add it to transactions error message
					commit("updateRequestError", err.message, { module: "transaction" });
				});
		},
		async createTransaction({ commit }, item) {
			commit("updateRequestError", null, { module: "transaction" });
			await API(REQUEST_METHODS.POST, "/transactions", item)
				.then((createdTransaction) => {
					commit("addTransaction", createdTransaction, {
						module: "transaction",
					});
				})
				.catch((err) => {
					console.error(err);
					commit("updateRequestError", err.message, { module: "transaction" });
				});
		},
		async updateTransaction({ commit }, item) {
			commit("updateRequestError", null, { module: "transaction" });
			await API(REQUEST_METHODS.PUT, `/transactions/${item.id}`, {
				username: item.newTransaction,
				password: item.password,
			})
				.then((updatedTransaction) => {
					commit("updateTransaction", updatedTransaction, {
						module: "transaction",
					});
				})
				.catch((err) => {
					console.error(err);
					commit("updateRequestError", err.message, { module: "transaction" });
				});
		},
		async updateTransactionAsDisbursed({ commit }, item) {
			commit("updateRequestError", null, { module: "transaction" });
			await API(REQUEST_METHODS.PUT, `/transactions/${item.id}`, {
				statusID: item.statusID,
			})
				.then((updatedTransaction) => {
					commit("updateManualDisbursement", updatedTransaction, {
						module: "transaction",
					});
				})
				.catch((err) => {
					console.error(err);
					commit("updateRequestError", err.message, { module: "transaction" });
				});
		},
		async deleteTransaction({ commit }, id) {
			commit("updateRequestError", null, { module: "transaction" });
			await API(REQUEST_METHODS.DELETE, `/transactions/${id}`)
				.then(({ deletedTransaction }) => {
					commit("deleteTransaction", deletedTransaction, {
						module: "transaction",
					});
				})
				.catch((err) => {
					console.error(err);
					commit("updateRequestError", err.message, { module: "transaction" });
				});
		},
	},
	namespaced: true,
};
